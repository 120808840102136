import "./ChiSiamoStyles.css"
import image from '../../img/aboutus2.jpg';

export const ChiSiamotxt2 = () => {
    return (
        <div className='text-box-r'>
        <div className='text-box-img-r'>
            <img src={image} alt='immagine 3'/>
        </div>
        <div className='text-box-txt-r'>
          <h3>Come Lavoriamo</h3>
          <p>La nostra metodologia di lavoro è basata sulla precisione e sull'attenzione ai dettagli. Ogni progetto inizia con un'analisi approfondita delle tue esigenze e dei tuoi obiettivi specifici. Dopo la pianificazione accurata del progetto, procediamo con l'installazione e l'implementazione delle soluzioni, sempre nel rispetto delle normative di sicurezza e delle migliori pratiche del settore. Ci impegniamo costantemente per garantire che ogni sistema elettrico o tecnologico che realizziamo funzioni in modo impeccabile, assicurando la tua completa soddisfazione e tranquillità.</p>
        </div>
      </div>
    )
  }


export default ChiSiamotxt2