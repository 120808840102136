import { useState } from "react";
import PhotoAlbum from "react-photo-album";
import { Parallax } from 'react-parallax';
import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./AutomatismiStyles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// Importa tutte le immagini dalla cartella
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('./foto_automatismi', false, /\.(png|jpe?g|svg)$/));

const Automatismi = () => {
    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = useState(-1);
    
    return (
        <div className='Automatismi'>
            <Parallax className='image-automatismi'
                bgImage={images[0]} // Usa la prima immagine come sfondo
                bgImageAlt="imaggine top"
                strength={500}>

                <div className='box-automatismi'> 
                    <span className='img-txt'> automatismi </span>
                    <div className='space'/>
                    <a className="Contattaci-btn" href='/Contattaci'>Contattaci</a>
                </div>
            </Parallax>

            <div className='automatismi-col'>
                <h1 className='title-automatismi'>I nostri lavori</h1>
                <a className ="Contattaci-btn" onClick={() => setOpen(true)}>
                    Galleria Fotografica
                </a>

                <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    slides={images.map((src, idx) => ({ src, caption: `Automatismi ${idx + 1}` }))}
                />
            </div>
        </div>
    );
}

export default Automatismi;
