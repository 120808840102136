import "./ChiSiamoStyles.css"
import image1 from '../../img/Mgp_immagine.jpg';
import image2 from '../../img/Mgp_moto.jpg';
export const ChiSiamotxt1 = () => {
    return (
        <div className='text-box'>
         <div className='text-box-txt'>
            <h3>Sponsor</h3>
            
           <a href="https://www.facebook.com/mgpracingofficine/?locale=it_IT" className="Sponsor_link"><img className="Sponsor_logo" src={image1}></img> MGP Racing - Officine Meccaniche Moto Sportive
           <img className="Sponsor_img" src={image2}></img></a>
            
         </div>
         
      </div>
    ) 
  }


export default ChiSiamotxt1