export const MenuData = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links',
        icon: 'fas fa-home'
    },
    {
        title: 'Chi Siamo?',
        url: '/ChiSiamo',
        cName: 'nav-links',
        icon: 'fas fa-info-circle' 
    },
    
    
];
