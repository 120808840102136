import "./ChiSiamoStyles.css"
import ChiSiamoTop from "./ChiSiamoTop";
import ChiSiamotxt1 from "./ChiSiamotxt1";
import ChiSiamotxt2 from "./ChiSiamotxt2";
import ChiSiamo_sponsor from "./ChiSiamo_sponsor";
import Footer_cert from "../Footer_cert/Footer_cert";
import React from "react";


function ChiSiamo() {
  return (
      <div className="chiSiamo">
        <div className="chisiamo-space"></div>
        <ChiSiamoTop/>
        <ChiSiamotxt1/>
        <ChiSiamotxt2/>
        <ChiSiamo_sponsor/>
        <Footer_cert/>

      </div>
    
   );
}

export default ChiSiamo;