import { Parallax } from 'react-parallax';
import "./AziendeStyles.css"
import image from "../../img/servaz.jpg"
import imgimpind from"../../img/impianntiind.jpg";




function Aziende() {
    return (
        <div className="Aziende"> 
        <Parallax className='image-az'
          bgImage={image}
          bgImageAlt="imaggine top"
          strength={500}>

          <div className='box-az'> 
            <span className='img-txt'> Sevizi per aziende </span>
            <div className='space'/>
            <a class="Contattaci-btn" role="button" href='/Contattaci'>Contattaci</a>
          </div>
        </Parallax>
    <table>
        <tr>
            <th>
                <a className='title-link' href='/Impiati-industriali'>Impianti inndustriali</a>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ut tristique elit, id euismod elit. Donec id enim in quam sagittis sollicitudin. Proin at dolor molestie, efficitur eros eget, eleifend nibh. In malesuada dolor vel sodales viverra. In congue dolor tincidunt commodo condimentum. Duis cursus tincidunt quam et pulvinar. Quisque ultrices metus lorem, quis fermentum mauris commodo egestas. Vestibulum gravida, dolor id sollicitudin varius, leo quam ornare est, vitae congue nibh libero vitae quam.</p>
            </th>
            <th>
            <img className='img-az' src={imgimpind} alt='immagine 1'/>
            </th>
        </tr>
     
         </table>
    </div>

    );
}

export default Aziende;







