import {Component} from "react";
import { MenuData } from "./MenuData";
import logo from '../../img/logo.gif';
import './NavbarStyle.css';

class Navbar extends Component {
  state = {clicked: false};
  handleClick = () => {
    this.setState({clicked: !this.state.clicked})
  }
  render() {
  return (
     <nav className="NavbarItems">
      <h1 className="logo"> <a href="/"> <img src= {logo} alt = "logo" className="logo"/></a></h1>
      <div className="menu-icons" onClick={this.handleClick}>
       <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>  
      </div>
      <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"} >
        {MenuData.map((item, index) => {
          return (
          <li key={index}>
            <a href={item.url} className={item.cName}> 
            <i className={item.icon}></i>{item.title}
            </a>
          </li>
          ); 
        })
      }
      <li className="dropdown">
        <a className="nav-links list-links" href="/Privati"> <i class="fa-solid fa-caret-down"></i>Servizi</a>
        <div class="dropdown-content">
          <a href="/Fotovoltaico">Fotovoltaico</a>
          <a href="/Impianti">Impianti elettrici</a>
          <a href="/Illuminotecnica">Illuminotecnica</a>
          <a href="/Automatismi">Automatismi</a>
          <a href="/Restauri">Restaurazione chiavi in mano</a>
        
          
        </div>
      </li>

      <li>
          <a href="/Contattaci" className="nav-links"> 
          <i className="fas fa-address-book"></i>Contattaci</a>

     </li>



      </ul>
     </nav>
  )
}
}

export default Navbar