import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import ContactForm from "./Components/Contattaci/ContactForm";
import ChiSiamo from "./Components/ChiSiamo/ChiSiamo";
import Footer from "./Components/Footer/Footer";
import Privati from "./Components/Servizi ai privati/Privati";
import Aziende from "./Components/Servizi alle aziende/Aziende";

//privati
import Fotovoltaico from "./Components/Servizi ai privati/Fotovoltaico/Fotovoltaico";
import Illuminotecnica from "./Components/Servizi ai privati/Illuminotecnica/Illuminotecnica";
import Impianti from "./Components/Servizi ai privati/Impianti/Impianti";
import Automatismi from "./Components/Servizi ai privati/Automatismi/Automatismi";
import Restauri from "./Components/Servizi ai privati/Restauri/Restauri";




import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./AppStyles.css";
import {
  BrowserRouter as Router,
  Routes ,
  Route,
} from "react-router-dom";

function App() {
  return (
       <div className="App">
          <Navbar/>
          
          <Router>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="*" element={<Home/>}/>
              <Route path="/Contattaci" element={<ContactForm/>}/>
              <Route path="/ChiSiamo" element={<ChiSiamo/>}/>
              <Route path="/Privati" element={<Privati/>}/>
              <Route path="/Aziende" element={<Aziende/>}/>
              
              <Route path="/Fotovoltaico" element={<Fotovoltaico/>}/>
              <Route path="/Illuminotecnica" element={<Illuminotecnica/>}/>
              <Route path="/Impianti" element={<Impianti/>}/>
              <Route path="/Automatismi" element={<Automatismi/>}/>
              <Route path="/Restauri" element={<Restauri/>}/>

            </Routes>
          </Router>
        
          <Footer/>
    </div>
  );
}

export default App;
