import React, { useState } from "react";
import './ContactFormStyles.css'



const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };
  return (
    <table className="pagina">
      <table className="box">
    <tr>
      <th className="form">
        <h2>Contattaci</h2>
    
     <i class="fas fa-phone"></i><b>Chiamaci: +39 380 1880540</b>
    
      
     <i class="fa-solid fa-envelope"></i><b>Email:  graiandreaimpianti@gmail.com</b>
    
      <i class="fas fa-location-dot"></i><b>Via Ripalta, 32, Serravalle Sesia, VC</b>
      
    
    </th>
   
    <th className="map-form">        
    <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d875.6014063749374!2d8.315162069675136!3d45.6828085351285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478611519d5eceef%3A0xd80c4864a0d975d1!2sVia%20Ripalta%2C%2032%2C%2013037%20Serravalle%20Sesia%20VC!5e1!3m2!1sit!2sit!4v1695309524738!5m2!1sit!2sit" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </th>
   
    </tr>
    </table>
    <div className="base-form">
  
   

     </div>
    </table>
     
  );
};

export default ContactForm;