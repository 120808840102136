import "./HomeStyles.css"
import img1 from "../../img/impiantipriv.jpg"
import img2 from "../../img/Mgp_paddoc.jpg"
import img3 from "../../img/Mgp_moto.jpg"
import img4 from "../../img/aboutustop.jpg"
import img5 from "../../img/servaz.jpg"
import img6 from "../../img/servpriv.jpg"
import fotoHome from "../../img/fotoHome.png"
import Footer_cert from "../Footer_cert/Footer_cert"
import Carousel from './Carousel'
function Home() {
  return (
    <>
    <div className="Home">
      <Carousel>
       
        <img src={img6} alt="fotovoltaico" style={{width: "100%", height: "100%"}} />
        <img src={img1} alt="impianto" style={{width: "100%", height: "100%"}} />
        <img src={img3} alt="mgp" style={{width: "100%", height: "100%"}} />
        <img src={img4} alt="illuminotenica" style={{width: "100%", height: "100%"}} />
        <img src={img5} alt="aziende" style={{width: "100%", height: "100%"}} />
        <img src={img2} alt="paddoc" style={{width: "100%", height: "100%"}} />
      </Carousel>
      <div className="Home-txt">
        <div className="home-row">
            <div className="home-col">
              <img className="fotoHome" src={fotoHome}></img>
            </div>
            <div className="home-col">
              <h1>Grai Andrea Impianti</h1>
              <h2>Impianti e non solo</h2>
              <ul>
              <li className ="listaHome">— Elettrici: industriali, privati </li>
              <li className ="listaHome">— Fotovoltaici: industriali, privati con certificazione ZCS</li>
              <li className ="listaHome">— Illuminotecnica: privati </li>
              <li className ="listaHome">— Automatismi: industriali, privati </li>
              <li className ="listaHome">— Restaurazioni chiavi in mano:privati  </li>
              </ul>
            </div>
          
        </div>
      </div>
      <Footer_cert/>
    </div>
    </>
   );
}

export default Home;