import { Parallax } from 'react-parallax';
import image1 from '../../img/aboutustop.jpg';
import './ChiSiamoStyles.css';

const ImageOne = () => (
    <Parallax className='image'
          bgImage={image1}
          bgImageAlt="imaggine 1"
          strength={200}>

          
            <div className='img-txt'> Chi Siamo?  </div>
           
    </Parallax>
);

export default ImageOne;