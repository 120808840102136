import { Parallax } from 'react-parallax';
import "./PrivatiStyles.css"
import image from "../../img/servpriv.jpg"
import imgfotovoltaico from"../../img/fotovoltaicopriv.jpg";
import imgilluminotecnica from "../../img/illuminotecnicapriv.jpg";
import imgimpianti from "../../img/impiantipriv.jpg";
import imgautomatismi from "../../img/automatismipriv.jpg";
import imgrestauri from "../../img/restauripriv.jpg";



function Privati() {
    return (
        <div className="Privati"> 
            <Parallax className='image-priv'
            bgImage={image}
            bgImageAlt="imaggine top"
            strength={500}>

            <div className='box-priv'> 
                <span className='img-txt'> Sevizi per privati </span>
                <div className='space'/>
                <a class="Contattaci-btn" role="button" href='/Contattaci'>Contattaci</a>
            </div>
            </Parallax>
    
        <div className='privati-row-r'>
            
            <div className='privati-col'>
            <img className='img-priv' src={imgfotovoltaico} alt='immagine 1'/>
            </div>
            <div className='privati-col'>
                <a className='title-link' href='/Fotovoltaico'>Fotovoltaico</a>
                <p>Siamo specializzati nell'installazione, manutenzione e riparazione di impianti fotovoltaici. Le nostre competenze sono garantite da certificazioni riconosciute nel settore, offrendo soluzioni energetiche sostenibili e sicure. </p>
            </div>
        </div>
        <div className='privati-row'> 
            <div className='privati-col'>
                <img className='img-priv' src={imgilluminotecnica} alt='immagine 2'/>
            </div> 
            <div className='privati-col'>
            <a className='title-link' href='/Illuminotecnica'>Illuminotecnica</a>
            <p>Offriamo servizi di illuminotecnica per creare ambienti luminosi e accoglienti, utilizzando tecnologie all'avanguardia. Personalizziamo soluzioni di illuminazione per soddisfare le tue esigenze.</p>
            </div>
        </div>
        <div className='privati-row-r'>
         <div className='privati-col'>
            <img className='img-priv' src={imgimpianti} alt='immagine 3'/>
            </div>
        <div className='privati-col'>
            <a className='title-link' href='/Impianti'>Impianti elettrici</a>
            <p>La sicurezza è la nostra priorità quando si tratta di impianti elettrici. Utilizziamo materiali di alta qualità e seguiamo rigorosamente le normative di sicurezza per garantire impianti residenziali e commerciali affidabili.</p>
        </div>
        </div>
        <div className='privati-row'>
            <div className='privati-col'>
                <img className='img-priv' src={imgautomatismi} alt='immagine 4'/>
            </div>
            <div className='privati-col'>
                <a className='title-link' href='/Automatismi'>Automatismi</a>
                <p>Progettiamo e installiamo sistemi automatizzati per rendere la tua vita più comoda ed efficiente, sia a livello domestico che industriale. </p>
            </div>
            </div>
        <div className='privati-row-r'>
            <div className='privati-col'>
                <img className='img-priv' src={imgrestauri} alt='immagine 5'/>
            </div>
            <div className='privati-col'>
                <a className='title-link' href='/Restauri'>Restaurazioni chiavi in mano</a>
                <p>Siamo il partner ideale per progetti di ristrutturazione chiavi in mano. Gestiamo tutto, dalla progettazione all'installazione, per garantire una ristrutturazione senza problemi e conveniente.</p>
            </div>
        </div>
         
    </div>

    );
}

export default Privati;







