import { useMemo,Children, useLayoutEffect, useState, useRef, useEffect, useCallback } from 'react'
import './Carousel.css'

const Carousel = ({children}) => {
        const containerRef = useRef();
        const intervalRef = useRef(null);
        const [current,setCurrent] = useState(1);
        const [tranlateX,setTranslateX] = useState(0);

        const actionHandler = useCallback((mode) => {
            containerRef.current.style.transitionDuration = "400ms";
            if(mode === "prev") {
                if(current <= 1){
                    setTranslateX(0);
                    setCurrent(children.length);
                }else{
                    setTranslateX(containerRef.current.clientWidth * (current - 1));
                    setCurrent((prev) => prev - 1);
                }
            } else if (mode === "next") {
                if(current >= children.length){
                    setTranslateX(containerRef.current.clientWidth * (children.length + 1));
                    setCurrent(1);
                }else{
                    setTranslateX(containerRef.current.clientWidth * (current + 1));
                    setCurrent((prev) => prev + 1);
                }
            }
    },[current, children]);

    //infinite scroll
    useEffect(() => {
        const transationEnd = () => {
            if(current <= 1) {
                containerRef.current.style.transitionDuration = "0ms";
                setTranslateX(containerRef.current.clientWidth * current)
            }

            if(current >= children.length) {
                containerRef.current.style.transitionDuration = "0ms";

                setTranslateX(containerRef.current.clientWidth * children.length)
            }
        };


        document.addEventListener("transitionend", transationEnd);

        return () => {
            document.removeEventListener("transitionend", transationEnd);
        };
    },[current, children]);

    //autoplay
    useEffect(() => {
        if(intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(() => {
            actionHandler("next");
        }, 3000);
        return () => {
            if(intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    },[actionHandler]);

    const slides = useMemo(() => {
        if(children.length > 1) {
            let items = Children.map(children, (child, index) => (
            <li key={index} className = "Slide">
            {child}
            </li>
            ));

            return [
                <li key={children.length + 1} className="Slide">
                    {children[children.length - 1]}
                </li>,
                ...items,
                <li key={children.length + 2} className="Slide">
                    {children[0]}
                </li>,
            ];
        }
        return <li className="Slide">{children[0]}</li>;
    }, [children]);

  useLayoutEffect(() => {
        setTranslateX(containerRef.current.clientWidth * current);
  }, []);

    return( <div className="Root">
        <ul 
            ref={containerRef}
            className="Container" 
            style = {{transform: `translate3d(${-tranlateX}px, 0, 0)`, transitionDuration: "400ms"}}
            >
                {slides}
            </ul>
            <div className="hovers">
                <span className="text-carousel">
                    <h2>Impianti elettrici - Fotovoltaico - Automatismi </h2>
                    <h3>Installatore certificato</h3>
                    <h2>Non esitare</h2>
                    <a class="Contattaci-btn" role="button" href='/Contattaci'>Contattaci</a>
                </span>    
                <button onClick={() => actionHandler("prev")} className="Btn Btn-left" ><i class="fas fa-angle-left"></i></button>
                <button onClick={() => actionHandler("next")} className="Btn Btn-right" ><i class="fas fa-angle-right"></i></button>
            </div>
        </div>
    );
};

export default Carousel;