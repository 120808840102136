import "./ChiSiamoStyles.css"
import image from '../../img/aboutus1.jpg';

export const ChiSiamotxt1 = () => {
    return (
        <div className='text-box'>
         <div className='text-box-txt'>
            <h3>Chi siamo?</h3>
            <p>La Ditta Grai Andrea Impianti è una solida realtà operante nel settore degli impianti elettrici e delle soluzioni tecnologiche. Con un'esperienza consolidata, ci dedichiamo a fornire servizi di alta qualità per soddisfare le esigenze dei nostri clienti e ad a offrire soluzioni personalizzate in ambito impianti elettrici civili e industriali, domotica, automazioni per cancelli e altro ancora. La nostra missione è mettere a disposizione la nostra competenza per garantire risultati innovativi e affidabili.</p>
         </div>
         <div className='text-box-img'>
            <img src={image} alt='immagine 2'/>
          </div>
      </div>
    )
  }


export default ChiSiamotxt1