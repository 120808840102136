import azzurrologo from '../../img/azzurozcs.png';
import fgaslogo from '../../img/fgascertlogo.jpg';
import certzcs from "../../Certificati/Andrea Grai - 230177.pdf"
import certfgas from "../../Certificati/ACFrOgDDsopIRPfOt3N1hF8iU12wVF9hkYt4V1s0lVx8MT9Rq-nNmOZYMyZrDajoTuH1QY_r9Bpkw3N1slMG0eQJw8kqFD9STTSsG1cxzZ9Kb4cZLudaAMknM6AXfZA=.pdf"

import './Footer_certStyles.css';

function Footer_cert() {
    return(
<div className="Certificazioni">
            <h3>Le nostre Certificazioni</h3>
            <table>
              <tr>
                <th><a href={certzcs}><img src={azzurrologo} className="logocert"/></a></th>
                <th><a href={certfgas}><img src={fgaslogo} className="logocert"/></a></th>
              </tr>
            </table>
        </div>
        );
}

export default Footer_cert;