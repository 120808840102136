import { Container } from "@mui/system";
import React from "react";
import "./FooterStyles.css";

const Footer = () => (
  <div className="footer">
  <Container>
    <div className="footer-row">
      <div className="footer-col">
        <ul>
          <p className="footer-title-list">Links</p>
          <li><i class="fas fa-home"></i><a class="footer-elemt" href="/"> Home</a></li>
          <li><i class="fas fa-info-circle"></i><a class="footer-elemt" href="/ChiSiamo"> Chi Siamo</a></li>
          <li><i class="fas fa-address-book"></i><a class="footer-elemt" href="/Contattaci"> Contattaci</a></li>
        </ul>
      </div>
      
      <div className="footer-col">
        <ul>
        <p className="footer-title-list">Recapiti</p>
          <p className="footer-list"><i class="fa-solid fa-envelope"></i> graiandreaimpianti@gmail.com</p>
          <p className="footer-list"><i class="fas fa-phone"></i> +39 380 1880540</p>
          <p className="footer-list"><i class="fas fa-location-dot"></i> Via Ripalta, 32, Serravalle Sesia, VC</p>
        </ul>
      </div>

      <div className="footer-col">
        <ul>
        <p className="footer-title-list">Social</p>
          <li><i class="fa-brands fa-linkedin"></i><a class="footer-elemt" href="https://it.linkedin.com/in/andrea-grai-187076ba"> Linkedin</a></li>
          <li><i class="fa-brands fa-facebook"></i><a class="footer-elemt" href="https://www.facebook.com/graiandreaimpianti/?locale=it_IT"> Facebook</a></li>
          <li><i class="fa-brands fa-instagram"></i><a class="footer-elemt" href="https://www.instagram.com/graiandrea74/"> Instagram</a></li>
        </ul>
      </div>
    </div>

  </Container>
  
  
  </div>
);

export default Footer;